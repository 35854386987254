import { Component } from '@angular/core';

@Component({
  selector: 'app-new-registration-chart',
  standalone: true,
  imports: [],
  templateUrl: './new-registration-chart.component.html',
  styleUrl: './new-registration-chart.component.scss'
})
export class NewRegistrationChartComponent {

}
