<div class="page-updates-list">
  <h1 class="mb-4">Updates List</h1>
  <div class="text-danger mb-4">
    Please click on the links below to view the updates. DO NOT use the side navigation bar to navigate to the pages.
  </div>


  <div class="mb-5">
    <h3 class="mb-3">Misc.</h3>

    <ol class="updates-list">
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-home/partner-home-featured-menu/listing']">
          <strong>[New]</strong> Partner home feature menu listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-home/partner-home-featured-menu/new']">
          <strong>[New]</strong> Create partner home feature menu
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-home/partner-home-notice/listing']">
          <strong>[Update]</strong> Partner home notice list
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-home/partner-home-notice/new']">
          <strong>[Update]</strong> Create partner home notice
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/learning-centre/learning-centre-home-banner/listing']">
          <strong>[New]</strong> Learning centre home banner listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/learning-centre/learning-centre-home-banner/new']">
          <strong>[New]</strong> Create learning centre home banner
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-home-banner/listing']">
          <strong>[New]</strong> Competition home banner listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-home-banner/new']">
          <strong>[New]</strong> Create competition home banner
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-featured-video/listing']">
          <strong>[New]</strong> Competition featured video listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-featured-video/new']">
          <strong>[New]</strong> Create competition featured video
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-steering-committee-banner/listing']">
          <strong>[New]</strong> Competition steering committee banner listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/competition/competition-steering-committee-banner/new']">
          <strong>[New]</strong> Create competition steering committee banner
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-notification/listing']">
          <strong>[New]</strong> Partner notification listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/partner-notification/new']">
          <strong>[New]</strong> Create partner notification
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/reporting']">
          <strong>[Update]</strong> Reporting
        </a>
      </li>
    </ol>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">BAP</h3>

    <ol class="updates-list">
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-topic/static-new']">
          <strong>[Update]</strong> Create BAP topic
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-listing']">
          <strong>[Update]</strong> BAP topic item listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-new']">
          <strong>[Update]</strong> Create BAP topic item
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> Create questionnaire question
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-new']">
              <strong>[Update]</strong> Create BAP topic item
            </a>, scroll to bottom "Questionnaire" section, click on the "Create new question" button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> Create quiz question
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-new']">
              <strong>[Update]</strong> Create BAP topic item
            </a>, scroll to bottom "Quiz" section, click on the "Create new question" button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> Delete question
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-new']">
              <strong>[Update]</strong> Create BAP topic item
            </a>, scroll to bottom "Questionnaire" / "Quiz" section, click on the "Edit" button on the question you want
            to
            delete, then click on the "Delete" button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> Duplicate question
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-topic-item/static-new']">
              <strong>[Update]</strong> Create BAP topic item
            </a>, scroll to bottom "Questionnaire" / "Quiz" section, click on the "Edit" button on the question you
            want to
            duplicate, then click on the "Duplicate" button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/listing']">
          <strong>[New]</strong> BAP uploads listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/listing']">
          <strong>[New]</strong> BAP upload listing multi select mode
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-uploads/listing']">
              <strong>[New]</strong> BAP uploads listing
            </a>, click on the 'Select' button on the top right corner of the
            table to enter multi
            select
            mode</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
          <strong>[New]</strong> BAP upload details
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
          <strong>[New]</strong> Verify upload modal
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
              <strong>[New]</strong> BAP uploads details
            </a>, click on the 'Verify upload' button at the bottom of the page</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
          <strong>[New]</strong> Reject upload modal
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
              <strong>[New]</strong> BAP uploads details
            </a>, click on the 'Reject upload' button at the bottom of the page</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
          <strong>[New]</strong> Bulk verify upload modal
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-uploads/listing']">
              <strong>[New]</strong> BAP uploads listing
            </a>, click on the 'Select' button on the top right corner of the
            table, select one or
            more
            items, then click on the 'Verify' button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-uploads/details']">
          <strong>[New]</strong> Bulk reject upload modal
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/bap/bap-uploads/listing']">
              <strong>[New]</strong> BAP uploads listing
            </a>, click on the 'Select' button on the top right corner of the
            table, select one or
            more
            items, then click on the 'Reject' button</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> BAP history modal
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-home-banner/listing']">
          <strong>[New]</strong> BAP home banner listing
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/bap/bap-home-banner/new']">
          <strong>[New]</strong> Create BAP home banner
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/manage-partners/all-partners']">
          <strong>[Update]</strong> Edit partner
        </a>
        <div class="gray-600 mb-3"><i>Go to <a target="_blank" [routerLink]="['/manage-partners/all-partners']">
              All Partners
            </a>, click on one of the partner to edit</i></div>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/manage-partners/all-partners']">
          <strong>[New]</strong> BAP history modal
        </a>
        <div class="gray-600 mb-3"><i>Go into Edit partner page, click on the 'View BAP history' button on top right
            corner</i></div>
      </li>
    </ol>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">Email Templates</h3>

    <ol class="updates-list">
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/email-templates']">
          <strong>[New]</strong> Upload rejected notification
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/email-templates']">
          <strong>[New]</strong> Tier upgrade notification
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/email-templates']">
          <strong>[New]</strong> Tier downgrade notification
        </a>
      </li>
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['/email-templates']">
          <strong>[New]</strong> Upload proof reminder
        </a>
      </li>
    </ol>
  </div>

  <!-- <div class="mb-5">
    <h3 class="mb-3">Analytics</h3>

    <ol class="updates-list">
      <li class="updates-list-item">
        <a target="_blank" [routerLink]="['#']">
          <strong>[New]</strong> Partner activity
        </a>
      </li>
    </ol>
  </div> -->
</div>