import { Component } from '@angular/core';
import {
  GridModule, ButtonModule, CardComponent, AccordionButtonDirective, AccordionComponent, AccordionItemComponent, TemplateIdDirective
} from '@coreui/angular';

@Component({
  selector: 'app-email-templates',
  standalone: true,
  imports: [GridModule, ButtonModule, CardComponent, AccordionButtonDirective, AccordionComponent, AccordionItemComponent, TemplateIdDirective],
  templateUrl: './email-templates.component.html',
  styleUrl: './email-templates.component.scss'
})
export class EmailTemplatesComponent {
  items = [1, 2];
}
