import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@coreui/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmationModalComponent } from '../../../components/modal/logout-confirmation-modal/logout-confirmation-modal.component'
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { iconSubset } from '../../../icons/icon-subset';

import {
  AvatarModule,
  BreadcrumbModule,
  DropdownModule,
  GridModule,
  HeaderModule,
  SidebarModule,
  NavModule,
  BadgeModule,
  ButtonModule
} from '@coreui/angular';
import { AccountService } from 'src/app/_services/account.service';
import { RoleDisplayName } from 'src/app/enum/role.enum';

@Component({
  selector: 'app-default-header',
  standalone: true,
  templateUrl: './default-header.component.html',
  styleUrl: './default-header.component.scss',
  imports: [GridModule, DropdownModule, AvatarModule, BreadcrumbModule, HeaderModule, SidebarModule, CommonModule, NavModule, BadgeModule, IconModule, ButtonModule]
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  adminName!: string;
  adminEmail!: string;
  adminRole!: string;

  constructor(
    private modalService: NgbModal,
    iconSetService: IconSetService,
    private accountService: AccountService
  ) {
    super();
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.accountService.getAdminProfile().subscribe(({ role, allowedLanguages }) => {
      this.adminName = this.accountService.name;
      this.adminEmail = this.accountService.email;
      this.adminRole = RoleDisplayName[role!.toUpperCase() as keyof typeof RoleDisplayName];
    });
  }

  openLogoutConfirmationModal() {
    const modalRef = this.modalService.open(LogoutConfirmationModalComponent, {
      centered: true,
      windowClass: 'generic-error-message-modal',
      scrollable: true,
    });
    modalRef.componentInstance.modal = modalRef;
  }
}