import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, ButtonModule, TooltipModule } from '@coreui/angular';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SpinnerIcon } from "../../../../icons/icon-spinner";
import { SelectMenuInputComponent, DatetimerangepickerInputComponent } from "../../../../components/forms";
import { IconModule } from '@coreui/icons-angular';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-partner-segmentation-chart',
  standalone: true,
  imports: [CommonModule, GridModule, ButtonModule, TooltipModule, BaseChartDirective, SpinnerIcon, SelectMenuInputComponent, DatetimerangepickerInputComponent, IconModule],
  templateUrl: './partner-segmentation-chart.component.html',
  styleUrl: './partner-segmentation-chart.component.scss'
})
export class PartnerSegmentationChartComponent implements OnInit {
  protected ChartDataLabels = ChartDataLabels;

  inputMarketSelectInputConfig = {
    id: 'market-select-input',
    name: 'market-select-input',
    label: 'Market',
    sublabel: '',
    placeholder: 'Select a market',
    disabled: false,
    error_message: 'Please select a market',
    error: [''],
    value: 'ALL',
    options: [
      {
        value: 'ALL',
        label: 'All markets',
        disabled: false,
        selected: true
      },
      {
        value: '1',
        label: 'Hong Kong',
        disabled: false,
        selected: false
      },
    ]
  };

  inputTierSelectInputConfig = {
    id: 'tier-select-input',
    name: 'tier-select-input',
    label: 'Tier',
    sublabel: '',
    placeholder: 'Select a tier',
    disabled: false,
    error_message: 'Please select a tier',
    error: [''],
    value: 'ALL',
    options: [
      {
        value: 'ALL',
        label: 'All tiers',
        disabled: false,
        selected: true
      },
      {
        value: '1',
        label: 'Green',
        disabled: false,
        selected: false
      },
      {
        value: '2',
        label: 'SCA',
        disabled: false,
        selected: false
      },
      {
        value: '3',
        label: 'Star 1',
        disabled: false,
        selected: false
      },
      {
        value: '4',
        label: 'Star 2',
        disabled: false,
        selected: false
      },
      {
        value: '5',
        label: 'Star 3',
        disabled: false,
        selected: false
      },
    ]
  };

  inputPeriodSelectInputConfig = {
    id: 'period-select-input',
    name: 'period-select-input',
    label: 'Period',
    placeholder: 'Select date period',
    value: [],
    disabled: false,
    error_message: 'Please select a period',
    error: [''],
    min_date: new Date(),
    max_date: {
      unit: 'days',
      count: 31
    },
    picker_mode_single: false
  };

  constructor(private toastr: ToastrService) { }

  // Doughnut
  public pieChartLabels: string[] = [
    'Highly Active',
    'Active',
    'Not Active'
  ];

  public pieChartData: ChartData<'pie'> = {
    labels: this.pieChartLabels,
    datasets: [
      {
        data: [2341, 1123, 986],
        backgroundColor: [
          '#ff5f00',  // Highly Active
          '#ffbf00',  // Active
          '#4183d7',  // Not Active
        ]
      }
    ],
  };

  public pieChartType: ChartType = 'pie' as const;

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          label: (context) => {
            const value = context.raw as number;
            const sum = (context.dataset.data as number[]).reduce((a, b) => a + b, 0);
            const percentage = ((value / sum) * 100).toFixed(1);
            return ` ${value.toLocaleString()} (${percentage}%)`;
          }
        }
      },
      legend: {
        position: 'right' as const,
        align: 'center' as const,
        labels: {
          padding: 8,
          boxWidth: 15,
          boxHeight: 15,
          pointStyle: 'rectRounded',
          usePointStyle: true,
        }
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: (value: number) => value.toLocaleString(),
        anchor: 'end',
        align: 'start',
        padding: 6
      }
    }
  };

  onDownloadChart() {
    this.toastr.success('Downloading chart data...');
  }

  ngOnInit() { }
}
