import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from './_services/auth.service';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  template: '<router-outlet></router-outlet>',
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Starbucks Coffee Community Admin Portal';

  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService
  ) {
    this.titleService.setTitle(this.title);
    if (environment.clientAuthEnabled) {
      this.authService.clientAuth(authService.userIsLoggedIn());
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        window.scrollTo(0, 0);
        //return;
      }
    });
  }
}
