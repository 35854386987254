import { INavData } from '@coreui/angular';

// Default
export const navItems: INavData[] = [
  {
    name: '⭐ Updates List ⭐',
    url: '/updates-list',
    iconComponent: { name: 'cib-pinboard' }
  },
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   iconComponent: { name: 'cid-bar-chart' }
  // },
  {
    name: 'Reporting',
    title: true
  },
  {
    name: 'Reports',
    url: '/reports',
    iconComponent: { name: 'cid-spreadsheet' }
  },
  {
    name: 'Asset',
    title: true
  },
  {
    name: 'Asset Library',
    url: '/asset-library',
    iconComponent: { name: 'cid-images' }
  },
  {
    name: 'Manage Partners',
    title: true
  },
  {
    name: 'Partners',
    url: '/manage-partners',
    iconComponent: { name: 'cid-contacts' },
    children: [
      {
        name: 'All partners',
        url: '/manage-partners/all-partners/',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Bulk Update Partners Rank',
        url: '/manage-partners/bulk-update-partners',
        iconComponent: { name: 'cil-library' }
      }
    ]
  },
  {
    name: 'Manage Admins',
    title: true
  },
  {
    name: 'Admins',
    url: '/manage-admins',
    iconComponent: { name: 'cid-people' },
    children: [
      {
        name: 'All Admins',
        url: '/manage-admins/all-admins',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Add New Admin',
        url: '/manage-admins/new',
        iconComponent: { name: 'cil-user-plus' }
      }
    ]
  },
  {
    name: 'Manage Contents',
    title: true
  },
  {
    name: 'Public Home',
    url: '/public-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Public Home Banner',
        url: '/public-home/public-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Banner',
            url: '/public-home/public-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/public-home/public-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Public Home Article',
        url: '/public-home/public-home-article',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Article',
            url: '/public-home/public-home-article/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Article',
            url: '/public-home/public-home-article/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Home',
    url: '/partner-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Home Banner',
        url: '/partner-home/partner-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Banner',
            url: '/partner-home/partner-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/partner-home/partner-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Featured Menu',
        url: '/partner-home/partner-home-featured-menu',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Recommended List',
        url: '/partner-home/partner-home-recommended-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Explore List',
        url: '/partner-home/partner-home-explore-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Special Topic',
        url: '/partner-home/partner-home-special-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Special Topic',
            url: '/partner-home/partner-home-special-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Special Topic',
            url: '/partner-home/partner-home-special-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notice',
        url: '/partner-home/partner-home-notice',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notice',
            url: '/partner-home/partner-home-notice/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notice',
            url: '/partner-home/partner-home-notice/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notification',
        url: '/partner-home/partner-home-notification',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notification',
            url: '/partner-home/partner-home-notification/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notification',
            url: '/partner-home/partner-home-notification/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Event',
    url: '/event',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Event',
        url: '/event/event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event',
            url: '/event/event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event',
            url: '/event/event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Activity',
        url: '/event/event-activity',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Activity',
            url: '/event/event-activity/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Activity',
            url: '/event/event-activity/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Video',
        url: '/event/event-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Video',
            url: '/event/event-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Video',
            url: '/event/event-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Banner',
        url: '/event/event-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Banner',
            url: '/event/event-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Banner',
            url: '/event/event-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Featured Event',
        url: '/event/featured-event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Featured Event',
            url: '/event/featured-event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Featured Event',
            url: '/event/featured-event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Highlight',
        url: '/event/event-highlight',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Highlight',
            url: '/event/event-highlight/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Highlight',
            url: '/event/event-highlight/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Archive',
        url: '/event/event-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Archive',
            url: '/event/event-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Archive',
            url: '/event/event-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Schedule',
        url: '/event/event-schedule',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Schedule',
            url: '/event/event-schedule/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Schedule',
            url: '/event/event-schedule/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
    ]
  },
  {
    name: 'Learning Centre',
    url: '/learning-centre',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Learning Centre Home Banner',
        url: '/learning-centre/learning-centre-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Topic',
        url: '/learning-centre/learning-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Topic',
            url: '/learning-centre/learning-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Topic',
            url: '/learning-centre/learning-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Lesson',
        url: '/learning-centre/learning-lesson',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Lesson',
            url: '/learning-centre/learning-lesson/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Lesson',
            url: '/learning-centre/learning-lesson/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Filter',
        url: '/learning-centre/learning-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Filter',
            url: '/learning-centre/learning-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Filter',
            url: '/learning-centre/learning-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Competition',
    url: '/competition',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Competition Home Banner',
        url: '/competition/competition-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Home Banner',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Home Banner',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Featured Video',
        url: '/competition/competition-featured-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Featured Video',
            url: '/competition/competition-featured-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Featured Video',
            url: '/competition/competition-featured-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition',
        url: '/competition/competition',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Information',
        url: '/competition/competition-information',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Information',
            url: '/competition/competition-information/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Information',
            url: '/competition/competition-information/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Video',
        url: '/competition/competition-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Video',
            url: '/competition/competition-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Video',
            url: '/competition/competition-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee',
        url: '/competition/competition-steering-committee',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee',
            url: '/competition/competition-steering-committee/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee',
            url: '/competition/competition-steering-committee/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee Banner',
        url: '/competition/competition-steering-committee-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Year Filter',
        url: '/competition/competition-year-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Year Filter',
            url: '/competition/competition-year-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Year Filter',
            url: '/competition/competition-year-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Black Apron Program (BAP)',
    url: '/bap',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'BAP Home Banner',
        url: '/bap/bap-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Home Banner',
            url: '/bap/bap-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Home Banner',
            url: '/bap/bap-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic',
        url: '/bap/bap-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic',
            url: '/bap/bap-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic',
            url: '/bap/bap-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic Item',
        url: '/bap/bap-topic-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic Item',
            url: '/bap/bap-topic-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic Item',
            url: '/bap/bap-topic-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Uploads',
        url: '/bap/bap-uploads',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Uploads',
            url: '/bap/bap-uploads/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Workshop',
    url: '/workshop',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Workshop',
        url: '/workshop/workshop',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop',
            url: '/workshop/workshop/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop',
            url: '/workshop/workshop/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Banner',
        url: '/workshop/workshop-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Banner',
            url: '/workshop/workshop-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Banner',
            url: '/workshop/workshop-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Archive',
        url: '/workshop/workshop-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Archive',
            url: '/workshop/workshop-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Archive',
            url: '/workshop/workshop-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Notification',
    url: '/partner-notification',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Notification',
        url: '/partner-notification/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Partner Notification',
        url: '/partner-notification/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  },
  {
    name: 'Person',
    url: '/person',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Person',
        url: '/person/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Person',
        url: '/person/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  },
  {
    name: 'Pending Approval',
    url: '/pending-approval',
    iconComponent: { name: 'cid-bell-exclamation' }
  },
  {
    name: 'Audit Trails',
    url: '/audit-trails',
    iconComponent: { name: 'cid-clipboard' }
  },
  {
    name: 'Unique Pages',
    title: true
  },
  {
    name: 'Email Templates',
    url: '/email-templates',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Login',
    url: '/login',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'First Time Log In',
    url: '/first-time-login',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Account Activated',
    url: '/account-activated',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Forgot Password',
    url: '/forgot-password',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Forgot Password Notice',
    url: '/forgot-password-notice',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Reset Password',
    url: '/reset-password',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Reset Password Successful',
    url: '/reset-password-successful',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Error 404',
    url: '/404',
    iconComponent: { name: 'cid-folder-special' }
  },
  {
    name: 'Error 500',
    url: '/500',
    iconComponent: { name: 'cid-folder-special' }
  }
];

// ROLE_SUPER_ADMIN
export const superNavItems: INavData[] = [
  {
    name: '⭐ Updates List ⭐',
    url: '/updates-list',
    iconComponent: { name: 'cib-pinboard' }
  },
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   iconComponent: { name: 'cid-bar-chart' }
  // },
  {
    name: 'Reporting',
    title: true
  },
  {
    name: 'Reports',
    url: '/reports',
    iconComponent: { name: 'cid-spreadsheet' }
  },
  {
    name: 'Asset',
    title: true
  },
  {
    name: 'Asset Library',
    url: '/asset-library',
    iconComponent: { name: 'cid-images' }
  },
  {
    name: 'Manage Partners',
    title: true
  },
  {
    name: 'Partners',
    url: '/manage-partners',
    iconComponent: { name: 'cid-contacts' },
    children: [
      {
        name: 'All partners',
        url: '/manage-partners/all-partners/',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Bulk Update Partners Rank',
        url: '/manage-partners/bulk-update-partners',
        iconComponent: { name: 'cil-library' }
      }
    ]
  },
  {
    name: 'Manage Admins',
    title: true
  },
  {
    name: 'Admins',
    url: '/manage-admins',
    iconComponent: { name: 'cid-people' },
    children: [
      {
        name: 'All Admins',
        url: '/manage-admins/all-admins',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Add New Admin',
        url: '/manage-admins/new',
        iconComponent: { name: 'cil-user-plus' }
      }
    ]
  },
  {
    name: 'Manage Contents',
    title: true
  },
  {
    name: 'Public Home',
    url: '/public-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Public Home Banner',
        url: '/public-home/public-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Banner',
            url: '/public-home/public-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/public-home/public-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Public Home Article',
        url: '/public-home/public-home-article',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Article',
            url: '/public-home/public-home-article/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Article',
            url: '/public-home/public-home-article/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Home',
    url: '/partner-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Home Banner',
        url: '/partner-home/partner-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Banner',
            url: '/partner-home/partner-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/partner-home/partner-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Featured Menu',
        url: '/partner-home/partner-home-featured-menu',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Recommended List',
        url: '/partner-home/partner-home-recommended-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Explore List',
        url: '/partner-home/partner-home-explore-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Special Topic',
        url: '/partner-home/partner-home-special-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Special Topic',
            url: '/partner-home/partner-home-special-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Special Topic',
            url: '/partner-home/partner-home-special-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notice',
        url: '/partner-home/partner-home-notice',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notice',
            url: '/partner-home/partner-home-notice/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notice',
            url: '/partner-home/partner-home-notice/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notification',
        url: '/partner-home/partner-home-notification',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notification',
            url: '/partner-home/partner-home-notification/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notification',
            url: '/partner-home/partner-home-notification/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Event',
    url: '/event',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Event',
        url: '/event/event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event',
            url: '/event/event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event',
            url: '/event/event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Activity',
        url: '/event/event-activity',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Activity',
            url: '/event/event-activity/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Activity',
            url: '/event/event-activity/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Video',
        url: '/event/event-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Video',
            url: '/event/event-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Video',
            url: '/event/event-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Banner',
        url: '/event/event-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Banner',
            url: '/event/event-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Banner',
            url: '/event/event-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Featured Event',
        url: '/event/featured-event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Featured Event',
            url: '/event/featured-event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Featured Event',
            url: '/event/featured-event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Highlight',
        url: '/event/event-highlight',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Highlight',
            url: '/event/event-highlight/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Highlight',
            url: '/event/event-highlight/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Archive',
        url: '/event/event-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Archive',
            url: '/event/event-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Archive',
            url: '/event/event-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Schedule',
        url: '/event/event-schedule',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Schedule',
            url: '/event/event-schedule/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Schedule',
            url: '/event/event-schedule/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
    ]
  },
  {
    name: 'Learning Centre',
    url: '/learning-centre',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Learning Centre Home Banner',
        url: '/learning-centre/learning-centre-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Topic',
        url: '/learning-centre/learning-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Topic',
            url: '/learning-centre/learning-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Topic',
            url: '/learning-centre/learning-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Lesson',
        url: '/learning-centre/learning-lesson',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Lesson',
            url: '/learning-centre/learning-lesson/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Lesson',
            url: '/learning-centre/learning-lesson/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Filter',
        url: '/learning-centre/learning-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Filter',
            url: '/learning-centre/learning-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Filter',
            url: '/learning-centre/learning-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Competition',
    url: '/competition',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Competition Home Banner',
        url: '/competition/competition-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Home Banner',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Home Banner',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Featured Video',
        url: '/competition/competition-featured-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Featured Video',
            url: '/competition/competition-featured-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Featured Video',
            url: '/competition/competition-featured-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition',
        url: '/competition/competition',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Information',
        url: '/competition/competition-information',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Information',
            url: '/competition/competition-information/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Information',
            url: '/competition/competition-information/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Video',
        url: '/competition/competition-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Video',
            url: '/competition/competition-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Video',
            url: '/competition/competition-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee',
        url: '/competition/competition-steering-committee',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee',
            url: '/competition/competition-steering-committee/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee',
            url: '/competition/competition-steering-committee/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee Banner',
        url: '/competition/competition-steering-committee-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Year Filter',
        url: '/competition/competition-year-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Year Filter',
            url: '/competition/competition-year-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Year Filter',
            url: '/competition/competition-year-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Black Apron Program (BAP)',
    url: '/bap',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'BAP Home Banner',
        url: '/bap/bap-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Home Banner',
            url: '/bap/bap-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Home Banner',
            url: '/bap/bap-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic',
        url: '/bap/bap-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic',
            url: '/bap/bap-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic',
            url: '/bap/bap-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic Item',
        url: '/bap/bap-topic-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic Item',
            url: '/bap/bap-topic-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic Item',
            url: '/bap/bap-topic-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Uploads',
        url: '/bap/bap-uploads',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Uploads',
            url: '/bap/bap-uploads/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Workshop',
    url: '/workshop',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Workshop',
        url: '/workshop/workshop',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop',
            url: '/workshop/workshop/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop',
            url: '/workshop/workshop/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Banner',
        url: '/workshop/workshop-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Banner',
            url: '/workshop/workshop-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Banner',
            url: '/workshop/workshop-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Archive',
        url: '/workshop/workshop-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Archive',
            url: '/workshop/workshop-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Archive',
            url: '/workshop/workshop-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Notification',
    url: '/partner-notification',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Notification',
        url: '/partner-notification/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Partner Notification',
        url: '/partner-notification/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  },
  {
    name: 'Person',
    url: '/person',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Person',
        url: '/person/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Person',
        url: '/person/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  },
  {
    name: 'Pending Approval',
    url: '/pending-approval',
    iconComponent: { name: 'cid-bell-exclamation' }
  },
  {
    name: 'Audit Trails',
    url: '/audit-trails',
    iconComponent: { name: 'cid-clipboard' }
  }
];

// ROLE_SECURITY_ADMIN
export const securityNavItems: INavData[] = [
  // {
  //   name: 'Reporting',
  //   title: true
  // },
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cid-bar-chart' }
  },
  {
    name: 'Reports',
    url: '/reports',
    iconComponent: { name: 'cid-spreadsheet' }
  },
  {
    name: 'Manage Admins',
    title: true
  },
  {
    name: 'Admins',
    url: '/manage-admins',
    iconComponent: { name: 'cid-people' },
    children: [
      {
        name: 'All Admins',
        url: '/manage-admins/all-admins',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Add New Admin',
        url: '/manage-admins/new',
        iconComponent: { name: 'cil-user-plus' }
      }
    ]
  },
  {
    name: 'Audit Trails',
    url: '/audit-trails',
    iconComponent: { name: 'cid-clipboard' }
  }
];

// ROLE_MAKER_ADMIN
export const makerNavItems: INavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   iconComponent: { name: 'cid-bar-chart' }
  // },
  {
    name: 'Reporting',
    title: true
  },
  {
    name: 'Reports',
    url: '/reports',
    iconComponent: { name: 'cid-spreadsheet' }
  },
  {
    name: 'Asset',
    title: true
  },
  {
    name: 'Asset Library',
    url: '/asset-library',
    iconComponent: { name: 'cid-images' }
  },
  {
    name: 'Manage Partners',
    title: true
  },
  {
    name: 'Partners',
    url: '/manage-partners',
    iconComponent: { name: 'cid-contacts' },
    children: [
      {
        name: 'All partners',
        url: '/manage-partners/all-partners/',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Bulk Update Partners Rank',
        url: '/manage-partners/bulk-update-partners',
        iconComponent: { name: 'cil-library' }
      }
    ]
  },
  {
    name: 'Manage Contents',
    title: true
  },
  {
    name: 'Public Home',
    url: '/public-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Public Home Banner',
        url: '/public-home/public-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Banner',
            url: '/public-home/public-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/public-home/public-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Public Home Article',
        url: '/public-home/public-home-article',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Article',
            url: '/public-home/public-home-article/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Article',
            url: '/public-home/public-home-article/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Home',
    url: '/partner-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Home Banner',
        url: '/partner-home/partner-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Banner',
            url: '/partner-home/partner-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Public Home Banner',
            url: '/partner-home/partner-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Featured Menu',
        url: '/partner-home/partner-home-featured-menu',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Recommended List',
        url: '/partner-home/partner-home-recommended-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Explore List',
        url: '/partner-home/partner-home-explore-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Special Topic',
        url: '/partner-home/partner-home-special-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Special Topic',
            url: '/partner-home/partner-home-special-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Special Topic',
            url: '/partner-home/partner-home-special-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notice',
        url: '/partner-home/partner-home-notice',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notice',
            url: '/partner-home/partner-home-notice/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notice',
            url: '/partner-home/partner-home-notice/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Partner Home Notification',
        url: '/partner-home/partner-home-notification',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notification',
            url: '/partner-home/partner-home-notification/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Home Notification',
            url: '/partner-home/partner-home-notification/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Event',
    url: '/event',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Event',
        url: '/event/event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event',
            url: '/event/event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event',
            url: '/event/event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Activity',
        url: '/event/event-activity',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Activity',
            url: '/event/event-activity/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Activity',
            url: '/event/event-activity/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Video',
        url: '/event/event-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Video',
            url: '/event/event-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Video',
            url: '/event/event-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Banner',
        url: '/event/event-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Banner',
            url: '/event/event-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Banner',
            url: '/event/event-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Featured Event',
        url: '/event/featured-event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Featured Event',
            url: '/event/featured-event/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Featured Event',
            url: '/event/featured-event/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Highlight',
        url: '/event/event-highlight',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Highlight',
            url: '/event/event-highlight/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Highlight',
            url: '/event/event-highlight/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Archive',
        url: '/event/event-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Archive',
            url: '/event/event-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Archive',
            url: '/event/event-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Event Schedule',
        url: '/event/event-schedule',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Schedule',
            url: '/event/event-schedule/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Event Schedule',
            url: '/event/event-schedule/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
    ]
  },
  {
    name: 'Learning Centre',
    url: '/learning-centre',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Learning Centre Home Banner',
        url: '/learning-centre/learning-centre-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Topic',
        url: '/learning-centre/learning-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Topic',
            url: '/learning-centre/learning-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Topic',
            url: '/learning-centre/learning-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Lesson',
        url: '/learning-centre/learning-lesson',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Lesson',
            url: '/learning-centre/learning-lesson/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Lesson',
            url: '/learning-centre/learning-lesson/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Learning Filter',
        url: '/learning-centre/learning-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Filter',
            url: '/learning-centre/learning-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Learning Filter',
            url: '/learning-centre/learning-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Competition',
    url: '/competition',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Competition Home Banner',
        url: '/competition/competition-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Home Banner',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Home Banner',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Featured Video',
        url: '/competition/competition-featured-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Featured Video',
            url: '/competition/competition-featured-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Featured Video',
            url: '/competition/competition-featured-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition',
        url: '/competition/competition',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition',
            url: '/competition/competition/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Information',
        url: '/competition/competition-information',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Information',
            url: '/competition/competition-information/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Information',
            url: '/competition/competition-information/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Video',
        url: '/competition/competition-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Video',
            url: '/competition/competition-video/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Video',
            url: '/competition/competition-video/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee',
        url: '/competition/competition-steering-committee',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee',
            url: '/competition/competition-steering-committee/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee',
            url: '/competition/competition-steering-committee/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee Banner',
        url: '/competition/competition-steering-committee-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Competition Year Filter',
        url: '/competition/competition-year-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Year Filter',
            url: '/competition/competition-year-filter/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Competition Year Filter',
            url: '/competition/competition-year-filter/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Black Apron Program (BAP)',
    url: '/bap',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'BAP Home Banner',
        url: '/bap/bap-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Home Banner',
            url: '/bap/bap-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Home Banner',
            url: '/bap/bap-home-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic',
        url: '/bap/bap-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic',
            url: '/bap/bap-topic/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic',
            url: '/bap/bap-topic/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'BAP Topic Item',
        url: '/bap/bap-topic-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic Item',
            url: '/bap/bap-topic-item/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create BAP Topic Item',
            url: '/bap/bap-topic-item/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Workshop',
    url: '/workshop',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Workshop',
        url: '/workshop/workshop',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop',
            url: '/workshop/workshop/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop',
            url: '/workshop/workshop/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Banner',
        url: '/workshop/workshop-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Banner',
            url: '/workshop/workshop-banner/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Banner',
            url: '/workshop/workshop-banner/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      },
      {
        name: 'Workshop Archive',
        url: '/workshop/workshop-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Archive',
            url: '/workshop/workshop-archive/listing',
            iconComponent: { name: 'cil-list' }
          },
          {
            name: 'Create Workshop Archive',
            url: '/workshop/workshop-archive/new',
            iconComponent: { name: 'cil-file-add' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Notification',
    url: '/partner-notification',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Notification',
        url: '/partner-notification/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Partner Notification',
        url: '/partner-notification/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  },
  {
    name: 'Person',
    url: '/person',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Person',
        url: '/person/listing',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Create Person',
        url: '/person/new',
        iconComponent: { name: 'cil-file-add' }
      }
    ]
  }
];

// ROLE_CHECKER_ADMIN
export const checkerNavItems: INavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   iconComponent: { name: 'cid-bar-chart' }
  // },
  {
    name: 'Reporting',
    title: true
  },
  {
    name: 'Reports',
    url: '/reports',
    iconComponent: { name: 'cid-spreadsheet' }
  },
  {
    name: 'Asset',
    title: true
  },
  {
    name: 'Asset Library',
    url: '/asset-library',
    iconComponent: { name: 'cid-images' }
  },
  {
    name: 'Manage Partners',
    title: true
  },
  {
    name: 'Partners',
    url: '/manage-partners',
    iconComponent: { name: 'cid-contacts' },
    children: [
      {
        name: 'All partners',
        url: '/manage-partners/all-partners/',
        iconComponent: { name: 'cil-list' }
      },
      {
        name: 'Bulk Update Partners Rank',
        url: '/manage-partners/bulk-update-partners',
        iconComponent: { name: 'cil-library' }
      }
    ]
  },
  {
    name: 'Manage Contents',
    title: true
  },
  {
    name: 'Public Home',
    url: '/public-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Public Home Banner',
        url: '/public-home/public-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Banner',
            url: '/public-home/public-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Featured Menu',
        url: '/partner-home/partner-home-featured-menu',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Featured Menu',
            url: '/partner-home/partner-home-featured-menu/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Public Home Article',
        url: '/public-home/public-home-article',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Public Home Article',
            url: '/public-home/public-home-article/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Home',
    url: '/partner-home',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Home Banner',
        url: '/partner-home/partner-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Banner',
            url: '/partner-home/partner-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Recommended List',
        url: '/partner-home/partner-home-recommended-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Recommended List Item',
            url: '/partner-home/partner-home-recommended-list-item/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Explore List',
        url: '/partner-home/partner-home-explore-list-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Explore List Item',
            url: '/partner-home/partner-home-explore-list-item/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Special Topic',
        url: '/partner-home/partner-home-special-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Special Topic',
            url: '/partner-home/partner-home-special-topic/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Notice',
        url: '/partner-home/partner-home-notice',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notice',
            url: '/partner-home/partner-home-notice/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Partner Home Notification',
        url: '/partner-home/partner-home-notification',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Partner Home Notification',
            url: '/partner-home/partner-home-notification/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Event',
    url: '/event',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Event',
        url: '/event/event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event',
            url: '/event/event/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Activity',
        url: '/event/event-activity',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Activity',
            url: '/event/event-activity/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Video',
        url: '/event/event-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Video',
            url: '/event/event-video/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Banner',
        url: '/event/event-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Banner',
            url: '/event/event-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Featured Event',
        url: '/event/featured-event',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Featured Event',
            url: '/event/featured-event/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Highlight',
        url: '/event/event-highlight',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Highlight',
            url: '/event/event-highlight/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Archive',
        url: '/event/event-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Archive',
            url: '/event/event-archive/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Event Schedule',
        url: '/event/event-schedule',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Event Schedule',
            url: '/event/event-schedule/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
    ]
  },
  {
    name: 'Learning Centre',
    url: '/learning-centre',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Learning Centre Home Banner',
        url: '/learning-centre/learning-centre-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Centre Home Banner',
            url: '/learning-centre/learning-centre-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Learning Topic',
        url: '/learning-centre/learning-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Topic',
            url: '/learning-centre/learning-topic/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Learning Lesson',
        url: '/learning-centre/learning-lesson',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Lesson',
            url: '/learning-centre/learning-lesson/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Learning Filter',
        url: '/learning-centre/learning-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Learning Filter',
            url: '/learning-centre/learning-filter/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Competition',
    url: '/competition',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Competition Home Banner',
        url: '/competition/competition-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Home Banner',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Featured Video',
        url: '/competition/competition-featured-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Featured Video',
            url: '/competition/competition-featured-video/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition',
        url: '/competition/competition',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition',
            url: '/competition/competition/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Information',
        url: '/competition/competition-information',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Information',
            url: '/competition/competition-information/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Video',
        url: '/competition/competition-video',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Video',
            url: '/competition/competition-video/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee',
        url: '/competition/competition-steering-committee',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee',
            url: '/competition/competition-steering-committee/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Steering Committee Banner',
        url: '/competition/competition-steering-committee-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Steering Committee Banner',
            url: '/competition/competition-steering-committee-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Competition Year Filter',
        url: '/competition/competition-year-filter',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Competition Year Filter',
            url: '/competition/competition-year-filter/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Black Apron Program (BAP)',
    url: '/bap',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'BAP Home Banner',
        url: '/bap/bap-home-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Home Banner',
            url: '/bap/bap-home-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'BAP Topic',
        url: '/bap/bap-topic',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic',
            url: '/bap/bap-topic/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'BAP Topic Item',
        url: '/bap/bap-topic-item',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Topic Item',
            url: '/bap/bap-topic-item/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'BAP Uploads',
        url: '/bap/bap-uploads',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'BAP Uploads',
            url: '/bap/bap-uploads/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Workshop',
    url: '/workshop',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Workshop',
        url: '/workshop/workshop',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop',
            url: '/workshop/workshop/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Workshop Banner',
        url: '/workshop/workshop-banner',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Banner',
            url: '/workshop/workshop-banner/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      },
      {
        name: 'Workshop Archive',
        url: '/workshop/workshop-archive',
        iconComponent: { name: 'cil-folder' },
        children: [
          {
            name: 'Workshop Archive',
            url: '/workshop/workshop-archive/listing',
            iconComponent: { name: 'cil-list' }
          }
        ]
      }
    ]
  },
  {
    name: 'Partner Notification',
    url: '/partner-notification',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Partner Notification',
        url: '/partner-notification/listing',
        iconComponent: { name: 'cil-list' }
      }
    ]
  },
  {
    name: 'Person',
    url: '/person',
    iconComponent: { name: 'cid-folder-alt' },
    children: [
      {
        name: 'Person',
        url: '/person/listing',
        iconComponent: { name: 'cil-list' }
      }
    ]
  },
  {
    name: 'Pending Approval',
    url: '/pending-approval',
    iconComponent: { name: 'cid-bell-exclamation' }
  }
];
