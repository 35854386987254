import { Component, OnInit } from '@angular/core';
import { GridModule, ButtonModule } from '@coreui/angular';
import { ListFiltersComponent } from "../../components/list-filters/list-filters.component";
import { IconModule } from '@coreui/icons-angular';
import { ListTableComponent } from "../../components/list-table/list-table.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContentService } from 'src/app/_services/content.service';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { CMSContentName, CMSContentPath } from 'src/app/enum/content.enum';
import { ErrorService } from 'src/app/_services/error.service';
import { ListPaginationComponent } from 'src/app/components/list-pagination/list-pagination.component';
import { AccountService } from 'src/app/_services/account.service';
import { catchError, combineLatest, of, switchMap, take, timeout } from 'rxjs';

@Component({
  selector: 'app-pending-approval-list',
  standalone: true,
  imports: [GridModule, ButtonModule, ListFiltersComponent, IconModule, ListTableComponent, CommonModule, FormsModule, ListPaginationComponent],
  templateUrl: './pending-approval-list.component.html',
  styleUrl: './pending-approval-list.component.scss'
})
export class PendingApprovalListComponent implements OnInit {
  listFilterConfig = {
    mode: 'CHECKER_PENDING_APPROVAL'
  }

  // API Call Config
  currentPage: number = 1;
  showAsset: number = 15;
  totalAsset: number = 0;
  totalPage: number = 1;
  searchInput: string = '';
  filter: {
    languageId: number;
    contentType: string;
    // category:string;
  } = {
      languageId: 1,
      contentType: 'ALL',
      // category : 'ALL'
    }

  adminRole !: string;
  adminAllowedLanguages !: number[];


  searchTerms = 'name, tag';

  tableHeadData = [
    {
      'value': 'Name',
      'class': 'col-wide-md content-id sticky'
    },
    {
      'value': 'Language'
    },
    {
      'value': 'Category',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Submission date',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Active period',
      'class': 'col-wide-lg'
    }
  ];

  dynamicTableBodyData: TableBodyDataModel[][] = [];

  constructor(
    private contentService: ContentService,
    private accountService: AccountService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    combineLatest([this.accountService.role$, this.accountService.allowedLanguages$]).pipe(
      timeout(5000),
      take(1),
      switchMap(([role, allowedLanguages]) => {
        if (role && allowedLanguages) {
          return of({ role, allowedLanguages });
        } else {
          return this.accountService.getAdminProfile().pipe(take(1));
        }
      }),
      catchError(() => {
        return of({ role: null, allowedLanguages: null });
      })
    ).subscribe(({ role, allowedLanguages }) => {
      this.adminRole = role!;
      this.adminAllowedLanguages = allowedLanguages!;
    })

    this.getOrSearch();
  }

  getOrSearch() {
    if (this.filter.languageId === undefined) {
      console.log('Please select a language!');
      return;
    }
    this.contentService.openFullscreenSpinnerModal();
    this.contentService.getContentListingWithPendingApproval(this.filter.languageId, this.currentPage, this.showAsset, this.filter.contentType, this.searchInput).subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          return;
        }

        this.dynamicTableBodyData = [];
        if (res.data.contents.length === 0) {
          this.totalAsset = 0;
          this.totalPage = 1;
          this.currentPage = 1;
          return;
        }

        const contentData = res.data.contents;
        const pageData = res.data.page;

        contentData.forEach((val: any) => {
          const cmsData = val.cms_data;
          const apiData = val.api_data;

          this.dynamicTableBodyData.push([
            {
              'type': 'TEXT',
              'value': cmsData.title,
              'url': `${CMSContentPath[cmsData.contentType.toUpperCase() as keyof typeof CMSContentPath]}/${cmsData.inode}`,
              'fromPendingApproval': true,
              'class': 'content-id sticky',
            },
            {
              'type': 'TEXT',
              'value': this.contentService.getLanguageCode(cmsData.languageId)
            },
            {
              'type': 'TEXT',
              'value': CMSContentName[cmsData.contentType.toUpperCase() as keyof typeof CMSContentName]
            },
            {
              'type': 'TEXT',
              'value': this.contentService.formatDateTime(apiData.last_edited_at),
              'subvalue': `by ${apiData.last_edited_by}`
            },
            {
              'type': 'DATE_RANGE',
              'value': this.contentService.formatDateTimeRange(cmsData.publishStartDate, cmsData.publishEndDate),
              ...(cmsData.publishStartDate || cmsData.publishEndDate ? { 'timezone': 'GMT+8' } : {})
            },
          ])
        });
        this.totalAsset = pageData.total_elements;
        this.totalPage = pageData.total_pages;
      }
    })
  };

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.getOrSearch();
  }

  setFilterValue(data: { formControlName: string, value: any }) {
    console.log(data);

    if (data.formControlName.toUpperCase() === 'LANGUAGE-INPUT') {
      this.filter.languageId = data.value;
    }

    if (data.formControlName.toUpperCase() === 'CATEGORY-INPUT') {
      this.filter.contentType = data.value;
    }

    this.getOrSearch();
  }

  setSearchInput(data: string) {
    this.searchInput = data;
    this.getOrSearch();
  }
}
