import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, ButtonModule, TooltipModule } from '@coreui/angular';
import { ChartConfiguration, ChartData, ChartEvent, ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SpinnerIcon } from "../../../../icons/icon-spinner";
import { SelectMenuInputComponent, DatetimerangepickerInputComponent } from "../../../../components/forms";
import { IconModule } from '@coreui/icons-angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-total-registered-partners-chart',
  standalone: true,
  imports: [CommonModule, GridModule, ButtonModule, TooltipModule, BaseChartDirective, SpinnerIcon, SelectMenuInputComponent, DatetimerangepickerInputComponent, IconModule],
  templateUrl: './total-registered-partners-chart.component.html',
  styleUrl: './total-registered-partners-chart.component.scss'
})
export class TotalRegisteredPartnersChartComponent implements OnInit {
  protected ChartDataLabels = ChartDataLabels;

  inputMarketSelectInputConfig = {
    id: 'market-select-input',
    name: 'market-select-input',
    label: 'Market',
    sublabel: '',
    placeholder: 'Select a market',
    disabled: false,
    error_message: 'Please select a market',
    error: [''],
    value: 'ALL',
    options: [
      {
        value: 'ALL',
        label: 'All markets',
        disabled: false,
        selected: true
      },
      {
        value: '1',
        label: 'Hong Kong',
        disabled: false,
        selected: false
      },
    ]
  };

  inputPeriodSelectInputConfig = {
    id: 'period-select-input',
    name: 'period-select-input',
    label: 'Period',
    placeholder: 'Select date period',
    value: [],
    disabled: false,
    error_message: 'Please select a period',
    error: [''],
    min_date: new Date(),
    max_date: {
      unit: 'days',
      count: 31
    },
    picker_mode_single: false
  };

  constructor(private toastr: ToastrService) { }

  // Doughnut
  public doughnutChartLabels: string[] = [
    'Green',
    'SCA',
    'Star 1',
    'Star 2',
    'Star 3'
  ];

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [2341, 1123, 986, 345, 456],
        circumference: 180,
        rotation: 270,
        backgroundColor: [
          '#34bc53',  // Green
          '#333333',  // SCA
          '#ff9f1c',  // Star 1
          '#ff7f15',  // Star 2
          '#ff5f0e'   // Star 3
        ]
      }
    ],
  };
  public doughnutChartType: ChartType = 'doughnut' as const;

  public doughnutChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          label: (context) => {
            const value = context.raw as number;
            const sum = (context.dataset.data as number[]).reduce((a, b) => a + b, 0);
            const percentage = ((value / sum) * 100).toFixed(1);
            return ` ${value.toLocaleString()} (${percentage}%)`;
          }
        }
      },
      legend: {
        position: 'right' as const,
        align: 'start' as const,
        labels: {
          padding: 8,
          boxWidth: 15,
          boxHeight: 15,
          pointStyle: 'rectRounded',
          usePointStyle: true,
        }
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: (value: number) => value.toLocaleString(),
        anchor: 'end',
        align: 'start',
        padding: 6
      }
    }
  };

  onDownloadChart() {
    this.toastr.success('Downloading chart data...');
  }

  ngOnInit() { }
}
