<section class="page-header border-0 pb-0">
  <c-container>
    <c-row>
      <c-col>
        <div class="page-heading text-center text-lg-start">
          <h1>Dashboard</h1>
        </div>

      </c-col>
    </c-row>
  </c-container>
</section>

<section class="page-contents">
  <c-container>
    <c-row>
      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">Total registered partners</h5>
            <button cButton color="primary" [cTooltip]="tooltipTotalRegisteredPartners"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipTotalRegisteredPartners>
              <div class="tooltip-content">
                This chart illustrates the total number of registered partners across different markets, categorized by
                tiers.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-total-registered-partners-chart></app-total-registered-partners-chart>
          </div>
        </div>

      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">Partner segmentation</h5>
            <button cButton color="primary" [cTooltip]="tooltipPartnerSegmentation"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipPartnerSegmentation>
              <div class="tooltip-content">
                This chart categorizes partners into three segments based on their time spent on the site.
                <ul class="mt-2 ps-3 mb-0">
                  <li>
                    <strong>Highly Active</strong> - more than 30 minutes daily
                  </li>
                  <li>
                    <strong>Active</strong> - less than 30 minutes daily
                  </li>
                  <li>
                    <strong>Not Active</strong> - did not visit site
                  </li>
                </ul>
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-partner-segmentation-chart></app-partner-segmentation-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              Partner visitation</h5>
            <button cButton color="primary" [cTooltip]="tooltipPartnerVisitation"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipPartnerVisitation>
              <div class="tooltip-content">
                This chart tracks number of partners visited the site daily.</div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-partner-visitation-chart></app-partner-visitation-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              Partner engagement</h5>
            <button cButton color="primary" [cTooltip]="tooltipPartnerEngagement"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipPartnerEngagement>
              <div class="tooltip-content">
                This chart illustrates engagement levels(views, click, bookmark, like) across activities: Avatar,
                Competition, Event, Learning center - over a set period.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-partner-engagement-chart></app-partner-engagement-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              BAP participation rate</h5>
            <button cButton color="primary" [cTooltip]="tooltipBAPParticipationRate"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipBAPParticipationRate>
              <div class="tooltip-content">
                This chart tracks the participation of partners in the BAP Activity, segmented by their current stage.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-bap-participation-rate-chart></app-bap-participation-rate-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              New registrations
            </h5>
            <button cButton color="primary" [cTooltip]="tooltipNewRegistrations"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipNewRegistrations>
              <div class="tooltip-content">
                This chart tracks the new partner registration.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-new-registration-chart></app-new-registration-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              BAP progress
            </h5>
            <button cButton color="primary" [cTooltip]="tooltipBAPProgress"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipBAPProgress>
              <div class="tooltip-content">
                This chart displays the number of partners at each progress stage for the selected tier or title to
                date.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-bap-progress-chart></app-bap-progress-chart>
          </div>
        </div>
      </c-col>

      <c-col lg="6" class="mb-4">
        <div class="card card-chart shadow-sm border-0">
          <div class="card-header d-flex flex-wrap align-items-center">
            <h5 class="mb-0">
              Title path
            </h5>
            <button cButton color="primary" [cTooltip]="tooltipTitlePath"
              class="ms-2 btn-tooltip btn-xs btn-circle">i</button>
            <ng-template #tooltipTitlePath>
              <div class="tooltip-content">
                This chart displays the number of partners at each path.
              </div>
            </ng-template>
          </div>
          <div class="card-body">
            <app-title-path-chart></app-title-path-chart>
          </div>
        </div>
      </c-col>
    </c-row>
  </c-container>
</section>