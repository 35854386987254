<section class="page-header">
  <c-container>
    <c-row>
      <c-col>
        <div class="page-heading text-center text-lg-start">
          <h1>Email Templates</h1>
          <p class="text-danger"><i>Notes: Email template size is NOT responsive</i></p>
        </div>

      </c-col>
    </c-row>
  </c-container>
</section>

<section class="page-contents listing">
  <c-container>
    <c-accordion>
      <c-accordion-item #item0="cAccordionItem" [visible]="true">
        <ng-template cTemplateId="accordionHeaderTemplate">
          <button (click)="item0.toggleItem()" [collapsed]="!item0.visible" cAccordionButton>
            <h4 class="mb-0">New Email Templates</h4>
          </button>
        </ng-template>
        <ng-template cTemplateId="accordionBodyTemplate">
          <div class="accordion-body py-5">
            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Upload rejected notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your upload has been rejected!</h1>
                                        Dear <strong>[user nickname]</strong>,<br>
                                        <br>
                                        Your upload has been rejected.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Star to
                                                  unlock:</span><br>
                                                <strong style="font-size: 15px">Verification for [Tier]</strong><br><br>
                                                <span style="font-size: 11px; color: #999999;">Upload date:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Reason:</span><br>
                                                <strong style="font-size: 15px; color: #FF0004">Image not clear</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Upload again">Upload again</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee Community Support Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Upload proof reminder</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Starbucks Coffee Community logo" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Upload your certification proof documents</h1>
                                        Dear <strong>[user nickname]</strong>,<br><br>
                                        You have not uploaded certification proof documents for your <strong>Star 1
                                          tier</strong>.<br><br>
                                        Please upload certification proof documents before <strong>21 May 2025 GMT+8
                                        </strong>to keep your tier.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td height="40" align="left">
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000;min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Upload proof">Upload
                                          proof</a>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee Community Support Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Tier upgrade notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Starbucks Coffee Community logo" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Congratulations on the upgrade!</h1>
                                        Dear <strong>[user nickname]</strong>,<br><br>
                                        Congratulations on successfully completing the <strong>Starbucks Coffee Academy
                                          (SCA) program</strong> and earning your certification as an <strong>SCA Coffee
                                          Master</strong>!<br><br>
                                        Please remember to upload your proof through "My Profile" within the next 30
                                        days to secure your status.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td height="40" align="left">
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000;min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Upload proof">Upload
                                          proof</a>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee Community Support Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Tier downgrade notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Starbucks Coffee Community logo" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          We apologize for the temporary downgrade!</h1>
                                        Dear <strong>[user nickname]</strong>,<br><br>
                                        We wanted to inform you that your rank has been downgraded to <strong>Star
                                          1</strong>.<br><br>
                                        Unfortunately, we have not received your certification proof documents of
                                        submission, resulting in a temporary downgrade. Please submit your certification
                                        proof documents through "My Profile" to regain your status.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="20"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="20" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee Community Support Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-template>
      </c-accordion-item>
      <c-accordion-item #item1="cAccordionItem" [visible]="false">
        <ng-template cTemplateId="accordionHeaderTemplate">
          <button (click)="item1.toggleItem()" [collapsed]="!item1.visible" cAccordionButton>
            <h4 class="mb-0">Existing Email Templates</h4>
          </button>
        </ng-template>
        <ng-template cTemplateId="accordionBodyTemplate">
          <div class="accordion-body py-5">
            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Admin Invitation</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Starbucks Coffee Community logo" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Admin Invitation</h1>
                                        Dear <strong>John Doe</strong>,<br><br>
                                        You’re invited to join our Starbucks Coffee Community Web Admin Portal.<br><br>
                                        Please log in with email <strong>john.doe&#64;starbucks.com</strong>
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td height="40" align="left">
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px;  text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer"
                                          title="Activate My Starbucks Coffee Community Account">Starbucks Coffee
                                          Community Web
                                          Admin Portal</a>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team</td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Admin OTP</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Admin OTP</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        To verify your email address, enter this code in the log-in page
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 18px; text-align: center; letter-spacing: 10px; color: #333333; line-height: 1.35;">
                                                <strong>123456</strong>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you didn‘t request a code, you can safely ignore this email.
                                        <br><br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Admin Reset Password</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Starbucks Coffee Community logo" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Reset Password</h1>
                                        Dear <strong>John Doe</strong>,<br><br>
                                        To reset your password, please click the button below:
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td height="40" align="left">
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000;min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Reset
                                          Password</a>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        Please copy the link to your browser if you are unable to click the button
                                        above.<br><br>

                                        <a href="#"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">https://xxxx.xxxx.xxxx/xxxxx</a><br><br>

                                        If you didn't request password reset, you can safely ignore this email.<br><br>

                                        If you require any assistance on how to use our services, please do not hesitate
                                        to
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br><br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Pending Checker Approval</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Has Been Successfully Submitted</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The following content has been successfully submitted and pending for approval:
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Advanced Coffee Brewing
                                                  Workshop</strong><br>
                                                <i style="color: #666666">xxxx-xxxxx-xxxxx</i><br><br><span
                                                  style="font-size: 11px; color: #999999;">Language:</span><br>
                                                <strong style="font-size: 15px">EN</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Submission Date &amp;
                                                  time:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">View
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Pending Approval For Checker</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Has Been Successfully Submitted</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The following content has been submitted and pending for approval:
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Advanced Coffee Brewing
                                                  Workshop</strong><br>
                                                <i style="color: #666666">xxxx-xxxxx-xxxxx</i><br><br><span
                                                  style="font-size: 11px; color: #999999;">Language:</span><br>
                                                <strong style="font-size: 15px">EN</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Submission Date &amp;
                                                  time:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">View
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Approved Notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Has Been Approved!</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The following content has been approved and will be automatically published
                                        based on the
                                        selected active period:
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Advanced Coffee Brewing
                                                  Workshop</strong><br>
                                                <i style="color: #666666">xxxx-xxxxx-xxxxx</i><br><br><span
                                                  style="font-size: 11px; color: #999999;">Language:</span><br>
                                                <strong style="font-size: 15px">EN</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Active period:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8) - 23:59 PM,
                                                  31 Dec
                                                  2024 (GMT+8)</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">View
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Rejected Notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Has Been Rejected!</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The follow article has been rejected.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Advanced Coffee Brewing
                                                  Workshop</strong><br>
                                                <i style="color: #666666">xxxx-xxxxx-xxxxx</i><br><br><span
                                                  style="font-size: 11px; color: #999999;">Language:</span><br>
                                                <strong style="font-size: 15px">EN</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Reason:</span><br>
                                                <strong style="font-size: 15px; color: #FF0004">Inappropriate cover
                                                  image</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">Edit
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Order Settings Pending Checker Approval</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Order Settings Has Been Successfully Submitted</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The following content has been submitted and pending for approval:
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Event banner</strong><br><br>
                                                <span style="font-size: 11px; color: #999999;">Submission Date &amp;
                                                  time:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">View
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Order Settings Approved Notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Order Settings Has Been Approved!</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The following content order settings has been approved and will be automatically
                                        published.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Event banner</strong><br><br>
                                                <span style="font-size: 11px; color: #999999;">Submission Date &amp;
                                                  time:</span><br>
                                                <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">View
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>

            <div class="mb-5 pb-3 border-bottom">
              <h4 class="text-center text-dark">Content Order Settings Rejected Notification</h4>

              <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
                <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="margin: 0 auto; table-layout:fixed;">
                  <tbody>

                    <!-- header -->
                    <tr>
                      <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                          alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
                    </tr>
                    <!-- header ends -->

                    <!-- Body Content -->
                    <tr>
                      <td bgcolor="#ffffff">
                        <table width="480" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <tr>
                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>

                              <!-- Inner Content -->
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0">
                                  <tbody>
                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <!-- Contents -->
                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <h1
                                          style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                          Your Content Order Settings Has Been Rejected!</h1>
                                        Dear <strong>John Doe</strong>,<br>
                                        <br>
                                        The follow content order settings has been rejected.
                                      </td>
                                    </tr>
                                    <!-- Contents ends -->

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td>
                                        <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                          style="border: 1px solid #dddddd;">
                                          <tbody>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20">&nbsp;</td>
                                              <td
                                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                                <span style="font-size: 11px; color: #999999;">Content:</span><br>
                                                <strong style="font-size: 15px">Advanced Coffee Brewing
                                                  Workshop</strong><br>
                                                <i style="color: #666666">xxxx-xxxxx-xxxxx</i><br><br><span
                                                  style="font-size: 11px; color: #999999;">Language:</span><br>
                                                <strong style="font-size: 15px">EN</strong>
                                                <br><br>
                                                <span style="font-size: 11px; color: #999999;">Reason:</span><br>
                                                <strong style="font-size: 15px; color: #FF0004">Reject reason goes
                                                  here</strong>
                                                <br><br>
                                                <a href="#"
                                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                                  target="_blank" rel="nofollow noopener noreferrer"
                                                  title="Verify now">Edit
                                                  content</a>
                                              </td>
                                              <td width="20">&nbsp;</td>
                                            </tr>
                                            <tr>
                                              <td width="20" height="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                              <td>&nbsp;</td>
                                              <td width="20">
                                                <img
                                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                                  width="20" height="20" style="display:block;" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                    <tr>
                                      <td align="left" valign="top"
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        If you require any assistance on how to use our services, please do not hesitate
                                        yo
                                        contact
                                        us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                          style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                          rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                        <br>
                                        Thank you.<br>
                                        Starbucks Coffee &amp; Partner Engagement Team
                                      </td>
                                    </tr>

                                    <!-- Empty space -->
                                    <tr>
                                      <td height="30"><img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="30" height="30" style="display:block;" /></td>
                                    </tr>
                                    <!-- Empty space ends -->

                                  </tbody>
                                </table>
                              </td>
                              <!-- Inner Content 560px ends -->

                              <td width="30" valign="top"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Body Content ends-->

                    <!-- Footer -->
                    <tr>
                      <td>
                        <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table"
                          bgcolor="#00754A">
                          <tbody>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="440" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                            <tr>
                              <td width="20" height="20">&nbsp;</td>
                              <td align="center"
                                style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                                Starbucks Coffee Community Website<br>
                                Copyright &copy; 2024. All rights reserved. </td>
                              <td width="20" height="20">&nbsp;</td>
                            </tr>
                            <tr>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                              <td width="560" height="20">&nbsp;</td>
                              <td width="20" height="20"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="20" height="20" style="display:block;" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- Footer ends -->

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-template>
      </c-accordion-item>
    </c-accordion>

  </c-container>
</section>