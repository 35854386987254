import { Component } from '@angular/core';

@Component({
  selector: 'app-bap-progress-chart',
  standalone: true,
  imports: [],
  templateUrl: './bap-progress-chart.component.html',
  styleUrl: './bap-progress-chart.component.scss'
})
export class BapProgressChartComponent {

}
