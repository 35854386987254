import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { AuthService } from 'src/app/_services/auth.service';
import { AbstractControl, FormControl, FormGroup, FormsModule, ValidatorFn, Validators } from '@angular/forms';
import { TextInputComponent } from 'src/app/components/forms';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { InputType } from 'src/app/enum';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';
import { ValidationService } from 'src/app/_services/validation.service';
import { ErrorService } from 'src/app/_services/error.service';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, GridModule, FormModule, FormsModule, CardModule, ButtonModule, IconModule, TextInputComponent, SpinnerIcon],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  requestOTPCountdown: number = 60;
  requestOTPCountdownStart: boolean = false;
  requestOTPButtonText: string = 'Request OTP';
  requestOTPInterval!: any;
  isRequestingOTP: boolean = false;

  resetPasswordToken: string = '';
  otpValue: string = '';

  // Form group
  resetPasswordForm!: FormGroup;
  isSubmitting: boolean = false;

  errorList!: string[];
  error: boolean = false;
  errorMessage!: string;
  landingError: boolean = false;
  landingErrorMessage!: string;

  // Form input - Email
  inputEmailConfig = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: true,
    type: InputType.Email,
  };

  // Form input - Password
  inputPasswordConfig: InputTextModel = {
    id: 'password-input',
    name: 'password-input',
    label: 'Password',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Password,
    required: true,
    show_password_tooltips: true,
    password_tooltips_error_list: []
  };

  // Form input - Confirm Password
  inputConfirmPasswordConfig: InputTextModel = {
    id: 'confirm-password-input',
    name: 'password-input',
    label: 'Confirm Password',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Password,
    required: true
  };


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private validationService: ValidationService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      'email-input': new FormControl(this.inputEmailConfig.value, [Validators.required]),
      'password-input': new FormControl(this.inputPasswordConfig.value, [Validators.required, this.passwordValidator()]),
      'confirm-password-input': new FormControl(this.inputConfirmPasswordConfig.value, [Validators.required]),
    });

    this.route.queryParams.subscribe(params => {
      if (!params['t']) {
        this.landingErrorMessage = 'Oops! Looks like that link to reset your password is invalid. Please request a new one.';
        this.landingError = true;
        return;
      }
      this.resetPasswordToken = params['t'];

      this.authService.verifyResetPassword(this.resetPasswordToken).subscribe({
        next: res => {
          if (res.data) {
            if (this.authService.userIsLoggedIn()) {
              localStorage.removeItem('loggedIn'); // Manually logout
              if (environment.clientAuthEnabled) {
                this.authService.clientAuth(this.authService.userIsLoggedIn());
              }
            }

            this.inputEmailConfig.value = res.data; // request return email
            this.resetPasswordForm.controls['email-input'].setValue(res.data);
            return;
          }
          this.landingErrorMessage = `${res.error}.`;
          this.landingError = true;
        }
      })
    })
  }

  onRequestOTP(): void {
    this.isRequestingOTP = true;

    // Password criteria error
    if (this.resetPasswordForm.controls['password-input'].errors) {
      window.postMessage('invalidPassword');
      this.errorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.error = true;
      this.isRequestingOTP = false;
      return;
    }

    // Mismatch password
    if (this.resetPasswordForm.controls['password-input'].value !== this.resetPasswordForm.controls['confirm-password-input'].value) {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.error = true;
      this.isRequestingOTP = false;
      return;
    }

    this.authService.requestOtpForResetPassword(this.resetPasswordToken, this.resetPasswordForm.controls['password-input'].value).subscribe({
      next: res => {
        if (res.data) {
          this.requestOTPCountdown = 60;
          this.requestOTPCountdownStart = true;
          this.requestOTPInterval = setInterval(() => {
            this.requestOTPCountdown--;
            if (this.requestOTPCountdown === 0) {
              clearInterval(this.requestOTPInterval);
              this.requestOTPCountdownStart = false;
              this.requestOTPButtonText = 'Request OTP';
            } else {
              this.requestOTPButtonText = `Request OTP (${this.requestOTPCountdown})`;
            }
          }, 1000);
          this.isRequestingOTP = false;
          return;
        }
        clearInterval(this.requestOTPInterval);
        this.requestOTPButtonText = 'Request OTP';
        this.requestOTPCountdownStart = false;
        this.errorMessage = res.error;
        this.error = true;
        this.isRequestingOTP = false;
      },
      error: err => {
        clearInterval(this.requestOTPInterval);
        this.requestOTPButtonText = 'Request OTP';
        this.requestOTPCountdownStart = false;
        this.errorMessage = err.error;
        this.error = true;
        this.isRequestingOTP = false;
      }
    })
  }

  onSubmitResetPassword() {
    this.isSubmitting = true;

    // Password criteria error
    if (this.resetPasswordForm.controls['password-input'].errors) {
      window.postMessage('invalidPassword');
      this.errorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.error = true;
      this.isSubmitting = false;
      return;
    }

    // Mismatch password
    if (this.resetPasswordForm.controls['password-input'].value !== this.resetPasswordForm.controls['confirm-password-input'].value) {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.error = true;
      this.isSubmitting = false;
      return;
    }

    this.authService.submitResetPassword(this.resetPasswordToken, this.resetPasswordForm.controls['password-input'].value, this.otpValue).subscribe({
      next: res => {
        this.isSubmitting = false;
        if (res.data) {
          this.router.navigateByUrl('/reset-password-successful');
          return;
        }
        this.errorMessage = res.error;
        this.error = true;
      },
      error: err => {
        this.errorMessage = err.error_description.slice(0, 7);
        this.error = true;
      }
    })
    this.isSubmitting = false;
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

  setTextInputValue(data: { formControlName: string, value: string }) {
    this.error = false;
    this.errorMessage = '';
    this.resetPasswordForm.controls[data.formControlName].setValue(data.value);
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;

      // Password validator criteria
      this.inputPasswordConfig.password_tooltips_error_list = this.validationService.validatePassword(password);
      this.errorList = this.validationService.validatePassword(password);

      if (this.errorList.length >= 1) {
        const firstError = this.errorList[0];
        return {
          [firstError]: true
        }
      }
      return null;
    }
  }
}
