<div class="chart">
  <div class="charts-tools d-flex flex-wrap align-items-start">
    <div class="filters d-flex flex-wrap">

      <div class="filter filter-lg">
        <div class="form-group">
          <app-datetimerangepicker-input [config]="inputPeriodSelectInputConfig">
          </app-datetimerangepicker-input>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="action">
        <button cButton cTooltip="Download chart data" variant="ghost" color="primary"
          class="btn-square btn-export-chart btn-sm" title="Download" (click)="onDownloadChart()"><svg cIcon
            name="cilCloudDownload"></svg></button>
      </div>
    </div>
  </div>

  <div class="chart-container">
    <canvas baseChart [data]="barChartData" [type]="barChartType" [options]="barChartOptions">
    </canvas>
  </div>
</div>