import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environment/environment';
import { Router } from '@angular/router';
import { ContentService } from '../_services/content.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private contentService: ContentService
    ) { }

    clientAuthEndpoints = [
        `${environment.prefixPath}${environment.adminPath}${environment.authPath}`
    ];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // send cookies in request
        request = request.clone({
            withCredentials: true
        });

        if (request.url.includes("logout")) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError(err => {
                console.log(`[ApiInterceptor] error`, err);

                if (err.status === 400) {
                    return throwError(() => err);
                }

                if (err.status === 401 && request.url.includes("/oauth/token")) {
                    return throwError(() => err);
                }

                if (err.status === 401 && !request.url.includes("/otp")) {
                    this.authService.userLogout();
                }

                if (err.status === 401 || err.status === 403) {
                    this.authService.userLogout();
                }

                // Content details not found
                if (err.status === 500 && err.url.includes("/details")) {
                    const currentUrl = this.router.url;
                    const parentUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
                    this.router.navigateByUrl(parentUrl);
                    this.contentService.spinnerModalRef.dismiss();
                    return throwError(() => err);
                }

                if (err.error != null) {
                    const error = err.error.message || err.error || err.statusText;
                    return throwError(() => error);
                }
                return throwError(() => err);
            })
        );
    }
}