import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, ButtonModule, TooltipModule } from '@coreui/angular';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SpinnerIcon } from "../../../../icons/icon-spinner";
import { SelectMenuInputComponent, DatetimerangepickerInputComponent } from "../../../../components/forms";
import { IconModule } from '@coreui/icons-angular';
import { ToastrService } from 'ngx-toastr';

interface VisitationData {
  date: string;
  count: {
    avatar: number;
    competition: number;
    learning: number;
    event: number;
  };
}

interface dataResponse {
  success: boolean;
  data: {
    market: string;
    period: {
      start: string;
      end: string;
    };
    visitations: VisitationData[];
  }
}

@Component({
  selector: 'app-bap-participation-rate-chart',
  standalone: true,
  imports: [
    CommonModule,
    GridModule,
    ButtonModule,
    TooltipModule,
    BaseChartDirective,
    SpinnerIcon,
    SelectMenuInputComponent,
    DatetimerangepickerInputComponent,
    IconModule
  ],
  templateUrl: './bap-participation-rate-chart.component.html',
  styleUrl: './bap-participation-rate-chart.component.scss'
})
export class BapParticipationRateChartComponent implements OnInit {
  protected ChartDataLabels = ChartDataLabels;

  inputPeriodSelectInputConfig = {
    id: 'period-select-input',
    name: 'period-select-input',
    label: 'Period',
    placeholder: 'Select date period',
    value: [],
    disabled: false,
    error_message: 'Please select a period',
    error: [''],
    min_date: new Date(),
    max_date: {
      unit: 'days',
      count: 31
    },
    picker_mode_single: false
  };

  inputTierSelectInputConfig = {
    id: 'tier-select-input',
    name: 'tier-select-input',
    label: 'Tier',
    sublabel: '',
    placeholder: 'Select a tier',
    disabled: false,
    error_message: 'Please select a tier',
    error: [''],
    value: 'ALL',
    options: [
      {
        value: 'ALL',
        label: 'All tiers',
        disabled: false,
        selected: true
      },
      {
        value: '1',
        label: 'Green',
        disabled: false,
        selected: false
      },
      {
        value: '2',
        label: 'SCA',
        disabled: false,
        selected: false
      },
      {
        value: '3',
        label: 'Star 1',
        disabled: false,
        selected: false
      },
      {
        value: '4',
        label: 'Star 2',
        disabled: false,
        selected: false
      },
      {
        value: '5',
        label: 'Star 3',
        disabled: false,
        selected: false
      },
    ]
  };

  // Bar Chart
  public barChartLabels: string[] = [];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: 'Avatar',
        data: [],
        backgroundColor: '#ffdf00',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      },
      {
        label: 'Competition',
        data: [],
        backgroundColor: '#ff9f00',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      },
      {
        label: 'Learning Center',
        data: [],
        backgroundColor: '#ff5f00',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      },
      {
        label: 'Event',
        data: [],
        backgroundColor: '#b7455b',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      }
    ],
  };

  public barChartType: ChartType = 'bar' as const;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw as number;
            return `${context.dataset.label}: ${value.toLocaleString()} engagements`;
          }
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          padding: 20,
          pointStyle: 'rectRounded',
          usePointStyle: true
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 10
          },
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Engagement',
          color: '#90A4AE',
          font: {
            size: 10
          }
        },
        ticks: {
          font: {
            size: 10
          }
        }
      }
    }
  };

  constructor(private toastr: ToastrService) { }

  updateChartData(response: dataResponse): void {
    const formatDate = (dateStr: string): string => {
      const date = new Date(dateStr);
      return `${date.getDate()} Mar`;
    };

    this.barChartData = {
      labels: response.data.visitations.map(item => formatDate(item.date)),
      datasets: [
        {
          label: 'Avatar',
          data: response.data.visitations.map(item => item.count.avatar),
          backgroundColor: '#ffdf00',
          borderRadius: 3,
          maxBarThickness: 20,
          barPercentage: 0.8
        },
        {
          label: 'Competition',
          data: response.data.visitations.map(item => item.count.competition),
          backgroundColor: '#ff9f00',
          borderRadius: 3,
          maxBarThickness: 20,
          barPercentage: 0.8
        },
        {
          label: 'Learning Center',
          data: response.data.visitations.map(item => item.count.learning),
          backgroundColor: '#ff5f00',
          borderRadius: 3,
          maxBarThickness: 20,
          barPercentage: 0.8
        },
        {
          label: 'Event',
          data: response.data.visitations.map(item => item.count.event),
          backgroundColor: '#b7455b',
          borderRadius: 3,
          maxBarThickness: 20,
          barPercentage: 0.8
        }
      ]
    };
  }

  onDownloadChart(): void {
    this.toastr.success('Downloading chart data...');
  }

  ngOnInit(): void {
    // Sample data for testing
    const chartDataResponse: dataResponse = {
      success: true,
      data: {
        market: "ALL",
        period: {
          start: "2024-03-11",
          end: "2024-03-24"
        },
        visitations: [
          { date: "2024-03-11", count: { avatar: 341, competition: 567, learning: 234, event: 123 } },
          { date: "2024-03-12", count: { avatar: 423, competition: 432, learning: 345, event: 234 } },
          { date: "2024-03-13", count: { avatar: 234, competition: 654, learning: 432, event: 145 } },
          { date: "2024-03-14", count: { avatar: 543, competition: 345, learning: 234, event: 167 } },
          { date: "2024-03-15", count: { avatar: 432, competition: 567, learning: 345, event: 189 } },
          { date: "2024-03-16", count: { avatar: 234, competition: 432, learning: 234, event: 145 } },
          { date: "2024-03-17", count: { avatar: 345, competition: 345, learning: 123, event: 167 } },
          { date: "2024-03-18", count: { avatar: 567, competition: 567, learning: 432, event: 234 } },
          { date: "2024-03-19", count: { avatar: 432, competition: 432, learning: 234, event: 167 } },
          { date: "2024-03-20", count: { avatar: 345, competition: 345, learning: 345, event: 189 } },
          { date: "2024-03-21", count: { avatar: 234, competition: 432, learning: 234, event: 145 } },
          { date: "2024-03-22", count: { avatar: 432, competition: 567, learning: 432, event: 167 } },
          { date: "2024-03-23", count: { avatar: 345, competition: 432, learning: 234, event: 189 } },
          { date: "2024-03-24", count: { avatar: 567, competition: 567, learning: 345, event: 234 } }
        ]
      }
    };

    this.updateChartData(chartDataResponse);
  }
}


