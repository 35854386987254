import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, ButtonModule, TooltipModule } from '@coreui/angular';
import {
  TotalRegisteredPartnersChartComponent,
  PartnerSegmentationChartComponent,
  PartnerVisitationChartComponent,
  TitlePathChartComponent,
  BapProgressChartComponent,
  NewRegistrationChartComponent,
  BapParticipationRateChartComponent,
  PartnerEngagementChartComponent
} from './components';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [GridModule, ButtonModule, TooltipModule, CommonModule, TotalRegisteredPartnersChartComponent, PartnerSegmentationChartComponent, PartnerVisitationChartComponent, TitlePathChartComponent, BapProgressChartComponent, NewRegistrationChartComponent, BapParticipationRateChartComponent, PartnerEngagementChartComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {

  ngOnInit() { }
}
