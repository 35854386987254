import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule, ButtonModule, TooltipModule } from '@coreui/angular';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SpinnerIcon } from "../../../../icons/icon-spinner";
import { SelectMenuInputComponent, DatetimerangepickerInputComponent } from "../../../../components/forms";
import { IconModule } from '@coreui/icons-angular';
import { ToastrService } from 'ngx-toastr';

interface VisitationData {
  date: string;
  count: number;
}

interface dataResponse {
  success: boolean;
  data: {
    market: string;
    period: {
      start: string;
      end: string;
    };
    visitations: VisitationData[];
  }
}

@Component({
  selector: 'app-partner-visitation-chart',
  standalone: true,
  imports: [
    CommonModule,
    GridModule,
    ButtonModule,
    TooltipModule,
    BaseChartDirective,
    SpinnerIcon,
    SelectMenuInputComponent,
    DatetimerangepickerInputComponent,
    IconModule
  ],
  templateUrl: './partner-visitation-chart.component.html',
  styleUrl: './partner-visitation-chart.component.scss'
})
export class PartnerVisitationChartComponent implements OnInit {
  protected ChartDataLabels = ChartDataLabels;

  inputPeriodSelectInputConfig = {
    id: 'period-select-input',
    name: 'period-select-input',
    label: 'Period',
    placeholder: 'Select date period',
    value: [],
    disabled: false,
    error_message: 'Please select a period',
    error: [''],
    min_date: new Date(),
    max_date: {
      unit: 'days',
      count: 31
    },
    picker_mode_single: false
  };

  // Bar Chart
  public barChartLabels: string[] = [];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: '#ff9f00',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      }
    ],
  };

  public barChartType: ChartType = 'bar' as const;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw as number;
            return `${value.toLocaleString()} partners`;
          }
        }
      },
      legend: {
        display: false
      },
      // datalabels: {
      //   color: '#333',
      //   font: {
      //     weight: 'bold',
      //     size: 7
      //   },
      //   formatter: (value: number) => value.toLocaleString(),
      //   anchor: 'end',
      //   align: 'end',
      //   padding: 6,
      //   rotation: -90
      // }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 10
          },
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Partners',
          color: '#90A4AE',
          font: {
            size: 10
          }
        },
        ticks: {
          font: {
            size: 10
          }
        }
      }
    }
  };

  constructor(private toastr: ToastrService) { }

  updateChartData(response: dataResponse): void {
    const formatDate = (dateStr: string): string => {
      const date = new Date(dateStr);
      return `${date.getDate()} Mar`;
    };

    this.barChartData = {
      labels: response.data.visitations.map(item => formatDate(item.date)),
      datasets: [{
        data: response.data.visitations.map(item => item.count),
        backgroundColor: '#ff9f00',
        borderRadius: 3,
        maxBarThickness: 20,
        barPercentage: 0.8
      }]
    };
  }

  onDownloadChart(): void {
    this.toastr.success('Downloading chart data...');
  }

  ngOnInit(): void {
    // Sample data for testing
    const chartDataResponse: dataResponse = {
      success: true,
      data: {
        market: "ALL",
        period: {
          start: "2024-02-24",
          end: "2024-03-24"
        },
        visitations: [
          { date: "2024-02-24", count: 2341 },
          { date: "2024-02-25", count: 1876 },
          { date: "2024-02-26", count: 2156 },
          { date: "2024-02-27", count: 1945 },
          { date: "2024-02-28", count: 2234 },
          { date: "2024-02-29", count: 2087 },
          { date: "2024-03-01", count: 1654 },
          { date: "2024-03-02", count: 1234 },
          { date: "2024-03-03", count: 1123 },
          { date: "2024-03-04", count: 2567 },
          { date: "2024-03-05", count: 2345 },
          { date: "2024-03-06", count: 2198 },
          { date: "2024-03-07", count: 1987 },
          { date: "2024-03-08", count: 1876 },
          { date: "2024-03-09", count: 1432 },
          { date: "2024-03-10", count: 1345 },
          { date: "2024-03-11", count: 2432 },
          { date: "2024-03-12", count: 2654 },
          { date: "2024-03-13", count: 2345 },
          { date: "2024-03-14", count: 2123 },
          { date: "2024-03-15", count: 1987 },
          { date: "2024-03-16", count: 1654 },
          { date: "2024-03-17", count: 1432 },
          { date: "2024-03-18", count: 2341 },
          { date: "2024-03-19", count: 2123 },
          { date: "2024-03-20", count: 1986 },
          { date: "2024-03-21", count: 1445 },
          { date: "2024-03-22", count: 2156 },
          { date: "2024-03-23", count: 1876 },
          { date: "2024-03-24", count: 2341 }
        ]
      }
    };

    this.updateChartData(chartDataResponse);
  }
}
