<div class="chart">
  <div class="charts-tools d-flex flex-wrap align-items-start">
    <div class="filters d-flex flex-wrap">
      <div class="filter filter-sm">
        <div class="form-group">
          <app-select-menu-input [config]="inputMarketSelectInputConfig"></app-select-menu-input>
        </div>
      </div>

      <div class="filter filter-sm">
        <div class="form-group">
          <app-select-menu-input [config]="inputTierSelectInputConfig"></app-select-menu-input>
        </div>
      </div>

      <div class="filter filter-lg">
        <div class="form-group">
          <app-datetimerangepicker-input [config]="inputPeriodSelectInputConfig">
          </app-datetimerangepicker-input>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="action">
        <button cButton cTooltip="Download chart data" variant="ghost" color="primary"
          class="btn-square btn-export-chart btn-sm" title="Download" (click)="onDownloadChart()"><svg cIcon
            name="cilCloudDownload"></svg></button>
      </div>
    </div>
    <!-- <c-row>
      <c-col xs="5" class="tool-col">
        <div class="tool">
          <div class="form-group">
            <app-select-menu-input [config]="inputMarketSelectInputConfig"></app-select-menu-input>
          </div>
        </div>
      </c-col>

      <c-col xs="5" class="tool-col">
        <div class="tool">
          <div class="form-group">
            <app-select-menu-input [config]="inputTierSelectInputConfig"></app-select-menu-input>
          </div>
        </div>
      </c-col>

      <c-col xs="2" class="tool-col d-flex align-self-end justify-content-end tool-export-chart">
        <div class="tool">
          <button cButton variant="outline" color="primary" class="btn-square btn-export-chart btn-sm"><svg cIcon
              name="cilCloudDownload"></svg></button>
        </div>
      </c-col>

      <c-col xs="10" class="tool-col">
        <div class="tool">
          <div class="form-group">
            <app-datetimerangepicker-input [config]="inputPeriodSelectInputConfig">
            </app-datetimerangepicker-input>
          </div>
        </div>
      </c-col>
    </c-row> -->
  </div>

  <div class="chart-container">

    <canvas baseChart [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions"
      [plugins]="[ChartDataLabels]">
    </canvas>
  </div>
</div>