<div class="chart">
  <div class="charts-tools d-flex flex-wrap align-items-start">
    <div class="filters d-flex flex-wrap">
      <div class="filter filter-sm">
        <div class="form-group">
          <app-select-menu-input [config]="inputMarketSelectInputConfig"></app-select-menu-input>
        </div>
      </div>

      <div class="filter filter-lg">
        <div class="form-group">
          <app-datetimerangepicker-input [config]="inputPeriodSelectInputConfig">
          </app-datetimerangepicker-input>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="action">
        <button cButton cTooltip="Download chart data" variant="ghost" color="primary"
          class="btn-square btn-export-chart btn-sm" title="Download" (click)="onDownloadChart()"><svg cIcon
            name="cilCloudDownload"></svg></button>
      </div>
    </div>
  </div>

  <div class="total-data d-flex flex-wrap align-items-center justify-content-center">
    <span class="total-data-value">26,432</span>
    <span class="total-data-label">partners</span>
  </div>

  <div class="chart-container">
    <canvas baseChart [data]="doughnutChartData" [type]="doughnutChartType" [options]="doughnutChartOptions"
      [plugins]="[ChartDataLabels]">
    </canvas>
  </div>
</div>